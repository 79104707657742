<ntw-product-detail-dialog *ngIf="productDetailDialogOpened" [close]="this.closeProductDetailDialog"
    [product]="this.selectedProduct" [companyNumber]="this.portalOrder.bkCompanyNumber">
</ntw-product-detail-dialog>
<div class="row">
    <div class="col pb-2">
        <div class="title border-bottom">
            {{"orderProcess.products.selectProductsCart" | translate}}
        </div>
    </div>
</div>
<div class="row justify-content-between g-2 pb-2">
    <div class="col-12 col-lg-4">
        <ntw-sku-filter (filterChanged)="onSkuFilterChanged($event)"></ntw-sku-filter>
    </div>
    <div class="col-12 col-lg-4">
        <kendo-textbox ntwExtended placeholder="{{'shared.filterItems' | translate}}" [clearButton]="true"
            [value]="this.searchValue" (afterValueChanged)="onAfterValueChanged($event)">
            <ng-template kendoTextBoxPrefixTemplate>
                <kendo-svg-icon class="border-end" [icon]="this.icons.search"></kendo-svg-icon>
            </ng-template>
        </kendo-textbox>
    </div>
    <div class="col-12 col-lg-3">
        <button kendoButton [svgIcon]="this.icons.cart" class="primaryButton"
            [disabled]="!isAnyProductSelected() || isAnyQuantityValueInvalid()" (click)="onUpdateCartClick()">
            {{ 'orderProcess.products.updateCart' | translate }}
        </button>
    </div>
</div>
<ntw-grid-template class="pt-2" gridTitle="products.title" [currentAccount]="this.currentAccount"
    [columnSettings]="this.columnSettings" idFieldName="AXCode" [entities]="this.entities"
    [(selectedEntity)]="this.selectedProduct" [selectableSettings]="this.selectableSettings"
    [dataGridView]="this.dataGridView" [summaries]="this.summaries" [loading]="this.loading"
    [dataStateChange]="this.dataStateChange" [showMenu]="false" [kendoGridState]="this.kendoGridState"
    [generateSummary]="this.getSelectedRecords" [customNavigatePath]="this.productsNavigationPath"
    [isEditingSelectedEntities]="true" [createFormGroup]="this.createFormGroup"
    [onDoubleClick]="this.openProductDetailDialog">
</ntw-grid-template>
