import { AfterViewChecked, Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { DataStateChangeEvent, SelectableSettings } from '@progress/kendo-angular-grid';
import { TextBoxComponent } from '@progress/kendo-angular-inputs';
import { CompositeFilterDescriptor, FilterDescriptor } from '@progress/kendo-data-query';
import { cartIcon, searchIcon } from '@progress/kendo-svg-icons';
import { Observable, Subscription, of, zip } from 'rxjs';
import { catchError, finalize, map, switchMap, tap } from 'rxjs/operators';
import { IInventoryService } from 'src/app/products/services/inventory.service.interface';
import { IProductService } from 'src/app/products/services/product.service.interface';
import { GridTemplateComponent } from 'src/app/shared/components/grid-template/grid-template.component';
import { ProductDetailDialogComponent } from 'src/app/shared/components/product-detail-dialog/product-detail-dialog.component';
import { selectProductsLineColumnsFormName } from 'src/app/shared/constants/form-names.consts';
import { LanguageCodes, NameFieldLanguageCodes } from 'src/app/shared/constants/language-codes';
import { SalesDistrict } from 'src/app/shared/constants/sales-district';
import { Selectors } from 'src/app/shared/constants/selectors';
import { StringConstants } from 'src/app/shared/constants/string-constants';
import { CrmAccount } from 'src/app/shared/models/account';
import { CosmosCustomer } from 'src/app/shared/models/customer';
import { FormConfiguration } from 'src/app/shared/models/form-configuration';
import { KendoGridBase } from 'src/app/shared/models/kendo-grid-base';
import { ODataResponse } from 'src/app/shared/models/odata-response';
import { PortalOrder } from 'src/app/shared/models/order-creation/portal-order';
import { ProductWithInventoryAndQuantity } from 'src/app/shared/models/order-creation/product-with-quantity';
import { InventoryOnline, InventoryOnlineStatus } from 'src/app/shared/models/products/inventory';
import { ColumnSettings } from 'src/app/shared/models/ui/column-settings';
import { SecurityService } from 'src/app/shared/security/security.service';
import { ICustomerService } from 'src/app/shared/services/customer/customer.service.interface';
import { FilterAndSortDescriptionService } from 'src/app/shared/services/filter-sort-description.service';
import { KendoAlertService } from 'src/app/shared/services/kendo-alerts.service';
import { CustomLanguageService } from 'src/app/shared/services/language.service';
import { OrderCartService } from 'src/app/shared/services/order-cart.service';
import { IPricelistService } from 'src/app/shared/services/pricelist/pricelist.service.interface';
import { CompanyAdminConfigurationStore } from 'src/app/shared/stores/company-admin-configuration.store';
import { selectProductColumns } from './select-product-columns';
import { ISkuFilterData } from './sku-filter/sku-filter/sku-filter-data';

@Component({
    templateUrl: './select-products.component.html',
    selector: 'ntw-order-select-products',
    styleUrls: ['./select-product.component.scss', '../../shared/styles/button.scss']
})
export class SelectProductsComponent extends KendoGridBase<ProductWithInventoryAndQuantity> implements OnInit, AfterViewChecked, OnDestroy {
    @Input() portalOrder: PortalOrder;
    @Output() portalOrderChange = new EventEmitter<PortalOrder>();
    @Input() inventoryOnline: InventoryOnline[];

    productDetailDialogOpened = false;

    @ViewChild(GridTemplateComponent) private gridTemplateComponent: GridTemplateComponent;
    @ViewChild(TextBoxComponent) searchBar: TextBoxComponent;

    @Input() searchValue: string;
    @Output() searchValueChange = new EventEmitter<string>();

    skuFilterData?: ISkuFilterData;

    currentAccount: CrmAccount;
    customer: CosmosCustomer;
    selectedProducts: ProductWithInventoryAndQuantity[];
    selectedProduct: ProductWithInventoryAndQuantity;
    formConfig: FormConfiguration;
    columnSettings: ColumnSettings[];

    icons = {
        cart: cartIcon,
        search: searchIcon
    }

    productsNavigationPath: string = null;

    public selectableSettings: SelectableSettings = {
        mode: 'multiple',
        drag: false,
        enabled: true,
    }

    createFormGroup = (product: ProductWithInventoryAndQuantity) => {
        const cartProduct = this.orderCartService.getProductByAxCode(product.AXCode);
        const quantity = product?.Quantity ?? cartProduct?.quantity ?? 1;

        return new UntypedFormGroup({
            AXCode: new UntypedFormControl(product.AXCode),
            Quantity: new UntypedFormControl(quantity, Validators.pattern("^[1-9][0-9]*")),
        })
    };

    constructor(
        private companyAdminConfigurationStore: CompanyAdminConfigurationStore,
        private customerService: ICustomerService,
        private filterSortService: FilterAndSortDescriptionService,
        private inventoryService: IInventoryService,
        private pricelistService: IPricelistService,
        private kendoAlertService: KendoAlertService,
        private languageService: CustomLanguageService,
        private orderCartService: OrderCartService,
        private productService: IProductService,
        private securityService: SecurityService,
        private translateService: TranslateService,
    ) {
        super();
    }

    private searchSubscription: Subscription;

    ngOnInit(): void {
        this.companyAdminConfigurationStore.getFormConfiguration(selectProductsLineColumnsFormName).pipe(tap(formConfig => {
            this.formConfig = formConfig;
            this.setColumnSettings();
        })).subscribe(() => {
            this.getListData();
            this.setNameColumn();
            this.translateService.onLangChange.subscribe(() => {
                this.setNameColumn();
            });
        });
    }

    public searchBarValueChangeSubscription: Subscription;

    ngAfterViewChecked(): void {
        if (!this.searchBarValueChangeSubscription && this.searchBar) {
            this.searchBarValueChangeSubscription = this.searchBar.valueChange.subscribe(value => {
                this.searchValue = value;
                this.searchValueChange.emit(value);
            });
        }
    }

    ngOnDestroy(): void {
        if (this.searchSubscription) {
            this.searchSubscription.unsubscribe();
        }
        if (this.searchBarValueChangeSubscription) {
            this.searchBarValueChangeSubscription.unsubscribe();
        }
    }

    @ViewChild('ntw-product-detail-dialog') productDetailDialog: ProductDetailDialogComponent;

    openProductDetailDialog = () => {
        this.productDetailDialogOpened = true;
    }

    closeProductDetailDialog = () => {
        this.productDetailDialogOpened = false;
    }

    private getListData() {
        this.securityService.getCurrentAccount().subscribe({
            next: currentAccount => {
                this.currentAccount = currentAccount;
                this.productsNavigationPath = 'account/' + this.currentAccount?.accountid + '/' + "products";
                if (this.searchValue) {
                    this.onAfterValueChanged(this.searchValue);
                    this.searchValueChange.emit(this.searchValue);
                }
                else {
                    this.getEntitiesForAccount();
                }
            },
            error: error => {
                this.kendoAlertService.showErrorAlert(error.error);
                this.loading = false;
            }
        });
    }

    private setColumnSettings() {
        if (!this.formConfig.fieldConfigurations.find(x => x.name === 'SalesPrice')) {
            this.formConfig.fieldConfigurations.push({
                name: 'SalesPrice',
                visible: false
            });
        }
        this.columnSettings = FormConfiguration.applyToColumnSettings(this.formConfig, [...selectProductColumns]);
    }

    private setNameColumn() {
        this.setColumnSettings();
        const translationNameField = this.getTranslationNameField();
        const i = this.columnSettings.findIndex(x => x.field === 'Name');
        if (translationNameField && i > -1) {
            this.columnSettings[i].field = translationNameField;
        }
    }

    onSkuFilterChanged(filterData: ISkuFilterData) {
        this.skuFilterData = filterData;
        this.kendoGridState.skip = 0;
        this.refreshView();
    }

    onUpdateCartClick(): void {
        if (!this.selectedProducts) {
            return;
        }
        else if (this.isAnyQuantityValueInvalid()) {
            this.kendoAlertService.showWarningAlert("orderProcess.products.invalidQuantity", true);
            return;
        }
        else if (this.gridTemplateComponent?.editedEntitiesInformation?.filter(x => x.formGroup.value.Quantity == null).length > 0) {
            this.gridTemplateComponent.editedEntitiesInformation.forEach(e => {
                if (e.formGroup.value.Quantity == null) {
                    e.formGroup.value.Quantity = 1;
                }
            });
        }
        this.gridTemplateComponent.saveEditedEntities();
        this.selectedProducts.forEach(product => {
            this.orderCartService.updateCartWithProduct(product);
            if (!this.inventoryOnline.find(inv => inv.axCode === product.AXCode)) {
                this.inventoryOnline.push({
                    axCode: product.AXCode,
                    status: InventoryOnlineStatus.Loading
                } as InventoryOnline);
            }
        });
        this.kendoAlertService.showSuccessAlert("orderProcess.products.productsAddedToCart", true);
        this.gridTemplateComponent.clearSelection();
        this.entities.forEach(e => e.Quantity = null);
        this.portalOrderChange.emit(this.portalOrder);
    }

    isAnyProductSelected(): boolean {
        return this.selectedProducts?.length > 0;
    }
    isAnyQuantityValueInvalid(): boolean {
        return this.gridTemplateComponent?.editedEntitiesInformation?.filter(x => x.formGroup.invalid).length > 0;
    }

    public getSelectedRecords = (selectedRecords: ProductWithInventoryAndQuantity[]) => {
        if (!selectedRecords) {
            return;
        }

        this.selectedProducts = selectedRecords;
    }

    override dataStateChange = (state: DataStateChangeEvent) => {
        this.kendoGridState = state;
        this.getEntitiesForAccount();
    }

    getEntitiesForAccount() {
        this.loading = true;
        if (this.searchSubscription) {
            this.searchSubscription.unsubscribe();
        }
        this.searchSubscription = this.customerService.getCustomerByCode(this.currentAccount.ntw_axcode).pipe(
            switchMap(customer => {
                this.customer = customer;
                if (!this.customer?.TikkurilaCompanyRegisterCode) {
                    this.kendoAlertService.showErrorAlert('alerts.axCustomerCompanyRegisterCodeMissing');
                    this.loading = false;
                    return null;
                }
                this.setDefaultFilters();
                const additionalFilters = this.getAdditionalProductFilters();
                return this.productService.getProductsWithFilters(
                    this.kendoGridState,
                    Selectors.selectProductsView,
                    additionalFilters
                ) as Observable<ODataResponse<ProductWithInventoryAndQuantity[]>>;
            }),
            switchMap(productsResponse => {
                if (!productsResponse || !productsResponse.value || productsResponse.value.length === 0) {
                    return of(productsResponse);
                }
                productsResponse.value.forEach(product => {
                    product.InventoryLoading = true;
                    product.SalesPriceLoading = true;
                    product.UnitNetPriceLoading = true;
                });
                return of(productsResponse);
            }),
            switchMap(productsResponse => {
                if (productsResponse.count === 0 || !productsResponse.value || productsResponse.value.length === 0) {
                    if (this.skuFilterData) {
                        this.kendoAlertService.showWarningAlert("alerts.noProductsFoundSKU", true);
                    }
                    else {
                        this.kendoAlertService.showWarningAlert("alerts.noProductsFound", true);
                    }
                    this.loading = false;
                    return of(productsResponse);
                }

                const $inventory = this.inventoryService.getInventoryForWarehouseByProductsOffline(this.portalOrder.warehouse.bkWarehouseKey, productsResponse.value).pipe(
                    map(inventoriesResponse => {
                        productsResponse.value.forEach(product => {
                            const inventory = inventoriesResponse.find(x => x.bkProductCoverageKey === `${product.AXCode}_${product.DataAreaID}_${this.portalOrder.warehouse.bkWarehouseKey}`);
                            if (inventory) {
                                const stock = inventory?.PhysicalInvent ?? 0;
                                product.Inventory = stock >= 0 ? stock : 0;
                                product.InventoryLoading = false;
                            }
                        });
                        return productsResponse;
                    }),
                    catchError(error => {
                        this.kendoAlertService.showErrorAlert(error);
                        return of(productsResponse);
                    }),
                    finalize(() => {
                        productsResponse.value.forEach(product => {
                            if (product.InventoryLoading) {
                                product.InventoryLoading = false;
                                product.InventoryNotAvailable = true;
                            }
                        });
                    })
                );


                const $pricelist = this.pricelistService.getPriceForMultipleProducts(productsResponse.value, this.customer).pipe(
                    map(pricelistResponse => {
                        productsResponse.value.forEach(product => {
                            const priceItem = pricelistResponse?.items?.find(x => x.id === product.AXCode);
                            if (priceItem && !priceItem.errorMsg) {
                                ProductWithInventoryAndQuantity.calculateProductPrice(product, priceItem);
                                product.SalesPriceLoading = false;
                                product.UnitNetPriceLoading = false;
                            }
                        });
                        return productsResponse;
                    }),
                    catchError(error => {
                        this.kendoAlertService.showErrorAlert(error);
                        return of(productsResponse);
                    }),
                    finalize(() => {
                        productsResponse.value.forEach(product => {
                            if (product.SalesPriceLoading) {
                                product.SalesPriceLoading = false;
                                product.SalesPriceNotAvailable = true;
                            }
                            if (product.UnitNetPriceLoading) {
                                product.UnitNetPriceLoading = false;
                                product.UnitNetPriceNotAvailable = true;
                            }
                        });
                    })
                );

                if (this.columnSettings.some(x => x.field === 'SalesPrice' || x.field === 'UnitNetPrice')) {
                    return zip($inventory, $pricelist).pipe(map(() => productsResponse));
                }
                else {
                    return $inventory.pipe(map(() => productsResponse));
                }
            }))
            .subscribe({
                next: oDataResponse => {
                    this.generateGridDataViewOData(oDataResponse);
                },
                error: error => {
                    this.kendoAlertService.showErrorAlert("alerts.errorDuringDataRetrieve");
                    console.error(error);
                    this.loading = false;
                }
            });
    }

    private getAdditionalProductFilters(): CompositeFilterDescriptor | undefined {
        const additionalFilters: CompositeFilterDescriptor = { logic: 'and', filters: [] };
        if (this.searchBarFilters) {
            additionalFilters.filters.push(this.searchBarFilters);
        }
        if (this.skuFilterData) {
            const skuFilter = this.getSkuFilter();
            if (skuFilter) {
                additionalFilters.filters.push(skuFilter);
            }
        }
        return additionalFilters.filters.length > 0 ? additionalFilters : undefined;
    }

    private getSkuFilter(): FilterDescriptor | undefined {
        if (!this.skuFilterData) {
            return undefined;
        }
        const level = this.skuFilterData.level;
        return this.filterSortService.createFilter(`SkuClassificationCode${level + 1}`, this.skuFilterData.code, "eq");
    }

    private setDefaultFilters() {
        this.setDataAreaFilter();
        this.setMasterItemTypeCodeFilter();
        this.setReleasedItemStatusFilter();
        this.setVisibleInWebShopFilter();
        this.setBrandFiltersBySalesDistrict();
    }

    private setDataAreaFilter() {
        const currentAreaId = this.customer.TikkurilaCompanyRegisterCode;
        const areaFilter = this.filterSortService.createFilter(StringConstants.ProductDataAreaField, currentAreaId, "eq");
        this.filterSortService.addFilter(this.kendoGridState, areaFilter);
    }

    private setMasterItemTypeCodeFilter() {
        const masterItemTypeCodeFilter = this.filterSortService.createFilter(StringConstants.MasterItemTypeCodeField, StringConstants.SalesArticlesMasterItemTypeCode, "eq");
        this.filterSortService.addFilter(this.kendoGridState, masterItemTypeCodeFilter);
    }

    private setReleasedItemStatusFilter() {
        const statusFilter = this.filterSortService.createCompositeFilter(StringConstants.ProductReleasedItemLifeCycleAxStatusField,
            [StringConstants.ProductActiveStatusValue, StringConstants.ProductToBeDiscontinuedStatusValue, StringConstants.ProductDiscontinuedStatusValue], "eq", 'or');
        this.filterSortService.addCompositeFilter(this.kendoGridState, statusFilter);
    }

    private setVisibleInWebShopFilter() {
        const visibleInWebShopFilter = this.filterSortService.createFilter(StringConstants.VisibleInWebShopField, StringConstants.VisibleInWebShopYes, "eq");
        this.filterSortService.addFilter(this.kendoGridState, visibleInWebShopFilter);
    }

    private setBrandFiltersBySalesDistrict() {
        if (!this.customer || !this.customer.SalesDistrict) {
            return;
        }
        const salesDistrict = this.customer.SalesDistrict;
        if (salesDistrict.startsWith(SalesDistrict.AlcroSweden)) {
            const brandFilter = this.filterSortService.createCompositeFilter(StringConstants.CommissionGroupField, [StringConstants.CommissionGroupAll, StringConstants.CommissionGroupAlcro], "eq", "or");
            this.filterSortService.addCompositeFilter(this.kendoGridState, brandFilter);
        }
        else if (salesDistrict.startsWith(SalesDistrict.BeckersSweden)) {
            const brandFilter = this.filterSortService.createCompositeFilter(StringConstants.CommissionGroupField, [StringConstants.CommissionGroupAll, StringConstants.CommissionGroupBeckers], "eq", "or");
            this.filterSortService.addCompositeFilter(this.kendoGridState, brandFilter);
        }
    }

    override refreshView(): void {
        this.getEntitiesForAccount();
    }

    override getQuickSearchFilters(query: string): FilterDescriptor[] {
        const filterArray = [];
        const nameLanguageField = this.getTranslationNameField();
        if (nameLanguageField) {
            filterArray.push(this.filterSortService.createFilter(`${nameLanguageField}_LC`, query.toLocaleLowerCase(), "contains"));
        }
        filterArray.push(this.filterSortService.createFilter(`NameLC`, query.toLocaleLowerCase(), "contains"));
        filterArray.push(this.filterSortService.createFilter("tolower(AXCode)", query.toLocaleLowerCase(), "contains"));
        filterArray.push(this.filterSortService.createFilter("tolower(EANCode)", query.toLocaleLowerCase(), "contains"));
        return filterArray;
    }

    private getTranslationNameField(): string {
        switch (this.languageService.currentLanguage) {
            case LanguageCodes.EnglishUSLanguageCode:
                return NameFieldLanguageCodes.EnglishUSNameField;
            case LanguageCodes.NorwegianLanguageCode:
                return NameFieldLanguageCodes.NorwegianNameField;
            case LanguageCodes.PolishLanguageCode:
                return NameFieldLanguageCodes.PolishNameField;
            case LanguageCodes.SwedishLanguageCode:
                return NameFieldLanguageCodes.SwedishNameField;
            case LanguageCodes.RussianLanguageCode:
                return NameFieldLanguageCodes.RussianNameField;
            default:
                return '';
        }
    }
}
