import { Component, EventEmitter, OnDestroy, OnInit, Output } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { filterClearIcon, filterIcon } from '@progress/kendo-svg-icons';
import { ISkuFilterData, skuFilterData } from './sku-filter-data';
import { Subject, takeUntil } from 'rxjs';

@Component({
  selector: 'ntw-sku-filter',
  templateUrl: './sku-filter.component.html',
  styleUrls: ['./sku-filter.component.scss', '../../../../shared/styles/button.scss']
})
export class SkuFilterComponent implements OnInit, OnDestroy {
  private readonly _destroying$ = new Subject<void>();

  @Output() public filterChanged = new EventEmitter<ISkuFilterData | undefined>();

  icons = {
    filter: filterIcon,
    filterClear: filterClearIcon
  }

  isTreeViewVisible = false;

  data = skuFilterData.map(item => {
    return {
      ...item,
      text: this.translateService.instant(item.text),
    }
  });

  selectedKeys = [];
  expandedKeys = [];

  get selectedItem(): ISkuFilterData {
    return this.data.find(item => item.code === this.selectedKeys[0]);
  }

  selectedItemHierarchy: ISkuFilterData[] = [];

  constructor(
    private translateService: TranslateService
  ) { }

  ngOnInit(): void {
    this.loadData();
    this.setupLanguageChangeHandler();
  }

  ngOnDestroy(): void {
    this._destroying$.next(null);
    this._destroying$.complete();
  }

  onSelectionChanged(): void {
    this.updateHierarchy();
    this.isTreeViewVisible = false;
    this.filterChanged.emit(this.selectedItem);
  }

  breadcrumbClicked(item: ISkuFilterData): void {
    this.selectedKeys = [item.code];
    this.onSelectionChanged();
  }

  clearSelection(): void {
    this.selectedKeys = [];
    this.onSelectionChanged();
  }

  private updateHierarchy(): void {
    if (!this.selectedItem) {
      this.selectedItemHierarchy = [];
      return;
    }

    const hierarchy = [(this.selectedItem)];

    let parent = this.selectedItem;
    while (parent && parent.parentCode) {
      parent = this.data.find(item => item.code === this.selectedItem.parentCode);
      hierarchy.unshift(parent);
    }

    this.selectedItemHierarchy = hierarchy;
  }

  private loadData(): void {
    this.data = skuFilterData.map(item => {
      return {
        ...item,
        text: this.translateService.instant(item.text),
      }
    });
  }

  private setupLanguageChangeHandler() {
    this.translateService.onLangChange.pipe(takeUntil(this._destroying$)).subscribe(() => {
      this.loadData();
      this.updateHierarchy();
    })
  }
}
